import React, { useState, useEffect } from "react";
import "./Prompter.css";

const Prompter = () => {
  const [query, setQuery] = useState("");
  const [queryInvalid, setQueryInvalid] = useState(false);
  const [humor, setHumor] = useState(3);
  const [englishLevel, setEnglishLevel] = useState(3);
  const [technicalDepth, setTechnicalDepth] = useState(3);
  const [length, setLength] = useState(3);
  const [tone, setTone] = useState(3);
  const [audience, setAudience] = useState(3);
  const [formality, setFormality] = useState(3);
  const [creativity, setCreativity] = useState(3);
  const [structure, setStructure] = useState(3);
  const [generatedPrompt, setGeneratedPrompt] = useState("");

  const HUMOR_MAP = {
    1: "None",
    2: "Light",
    3: "Moderate",
    4: "High",
    5: "Sarcastic",
  };
  const ENGLISH_LEVEL_MAP = {
    1: "B1",
    2: "B2",
    3: "C1",
    4: "C2",
    5: "Native",
  };
  const TECHNICAL_DEPTH_MAP = {
    1: "Beginner",
    2: "Low",
    3: "Medium",
    4: "High",
    5: "Expert",
  };
  const LENGTH_MAP = {
    1: "XS",
    2: "S",
    3: "M",
    4: "L",
    5: "XL",
  };
  const TONE_MAP = {
    1: "Casual",
    2: "Friendly",
    3: "Professional",
    4: "Authoritative",
    5: "Academic",
  };
  const AUDIENCE_MAP = {
    1: "Children",
    2: "General",
    3: "Business",
    4: "Technical",
    5: "Experts",
  };
  const FORMALITY_MAP = {
    1: "Informal",
    2: "Conversational",
    3: "Standard",
    4: "Formal",
    5: "Academic",
  };
  const CREATIVITY_MAP = {
    1: "Conservative",
    2: "Balanced",
    3: "Creative",
    4: "Highly Creative",
    5: "Experimental",
  };
  const STRUCTURE_MAP = {
    1: "Narrative",
    2: "Descriptive",
    3: "List",
    4: "Bullet Points",
    5: "Detailed Outline",
  };

  const LENGTH_CHARS = {
    xs: 1000,
    s: 2000,
    m: 5000,
    l: 8000,
    xl: 10000,
  };

  // Helper to compute slider background style
  const getSliderStyle = (value) => {
    const percentage = ((value - 1) / 4) * 100;
    return {
      background: `linear-gradient(90deg, #28a745 ${percentage}%, #555 ${percentage}%)`,
    };
  };

  const generatePrompt = () => {
    if (!query.trim()) {
      setQueryInvalid(true);
      return;
    } else {
      setQueryInvalid(false);
    }
    const promptParts = [
      `Generate content about "${query}".`,
      getHumorSentence(humor),
      getEnglishSentence(englishLevel),
      getTechnicalSentence(technicalDepth),
      getLengthSentence(length),
      getToneSentence(tone),
      getAudienceSentence(audience),
      getFormalitySentence(formality),
      getCreativitySentence(creativity),
      getStructureSentence(structure),
      "Ensure that the final content is well-structured, coherent, and aligns with these guidelines.",
    ];
    const prompt = promptParts.join(" ");
    setGeneratedPrompt(prompt);

    // Automatically copy prompt to clipboard and show a fixed top-center notification
    navigator.clipboard
      .writeText(prompt)
      .then(() => {
        showNotification("Prompt copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy prompt: ", err);
        showNotification("Failed to copy prompt.", true);
      });
  };

  const getHumorSentence = (value) => {
    if (value === 1)
      return "The content should be completely serious with no hint of humor.";
    if (value === 2) return "It should feature a subtle, light touch of humor.";
    if (value === 3)
      return "Incorporate a moderate dose of humor to keep it engaging.";
    if (value === 4)
      return "Infuse the content with a high level of humor to entertain the audience.";
    if (value === 5)
      return "The content should be saturated with sarcastic wit and humor.";
  };

  const getEnglishSentence = (value) => {
    if (value === 1)
      return "Use simple, everyday English suitable for B1 learners.";
    if (value === 2)
      return "Employ clear and straightforward language, appropriate for B2 proficiency.";
    if (value === 3)
      return "Demonstrate an advanced command of English at the C1 level.";
    if (value === 4)
      return "Utilize sophisticated language reflecting near-fluency at the C2 level.";
    if (value === 5)
      return "Express ideas with native-level fluency and nuance.";
  };

  const getTechnicalSentence = (value) => {
    if (value === 1)
      return "Keep technical explanations very basic, ideal for beginners.";
    if (value === 2)
      return "Present technical details in a simple and accessible manner.";
    if (value === 3)
      return "Offer a balanced level of technical detail that is neither too basic nor too advanced.";
    if (value === 4)
      return "Delve into technical aspects with considerable depth and complexity.";
    if (value === 5)
      return "Provide expert-level technical insights and a deep analytical perspective.";
  };

  const getLengthSentence = (value) => {
    const charLimit = LENGTH_CHARS[LENGTH_MAP[value]];
    if (value === 1)
      return `Keep the content succinct, with a minimum of ${charLimit} characters.`;
    if (value === 2)
      return `Ensure the content is fairly concise, containing at least ${charLimit} characters.`;
    if (value === 3)
      return `Craft content of moderate length, with no less than ${charLimit} characters.`;
    if (value === 4)
      return `Develop comprehensive content, requiring at least ${charLimit} characters.`;
    if (value === 5)
      return `Generate expansive and detailed content, with a minimum of ${charLimit} characters.`;
  };

  const getToneSentence = (value) => {
    if (value === 1) return "Adopt a casual and relaxed tone.";
    if (value === 2) return "Use a friendly and approachable tone.";
    if (value === 3)
      return "Maintain a professional tone that conveys expertise.";
    if (value === 4)
      return "Adopt an authoritative tone that inspires confidence.";
    if (value === 5)
      return "Embrace an academic tone with formal language and precision.";
  };

  const getAudienceSentence = (value) => {
    if (value === 1)
      return "Tailor the content to be engaging and accessible for children.";
    if (value === 2)
      return "Ensure the content is easily understood by a general audience.";
    if (value === 3)
      return "Design the content with a business audience in mind, focusing on clarity.";
    if (value === 4)
      return "Cater the content to a technical audience by incorporating relevant jargon.";
    if (value === 5)
      return "Target experts by providing in-depth analysis and detailed insights.";
  };

  const getFormalitySentence = (value) => {
    if (value === 1) return "Keep the language informal and relaxed.";
    if (value === 2)
      return "Adopt a conversational style that strikes a balance between casual and formal.";
    if (value === 3)
      return "Maintain a standard level of formality that is professional yet accessible.";
    if (value === 4)
      return "Use a formal style, ensuring the language is polished and refined.";
    if (value === 5)
      return "Craft the content with a highly formal and academic tone.";
  };

  const getCreativitySentence = (value) => {
    if (value === 1)
      return "The approach should be conservative and straightforward.";
    if (value === 2)
      return "Strike a balanced blend of creativity and structure.";
    if (value === 3)
      return "Incorporate creative and original ideas into the content.";
    if (value === 4)
      return "Push the boundaries with a highly creative approach.";
    if (value === 5)
      return "Adopt an experimental and avant-garde style, exploring unconventional ideas.";
  };

  const getStructureSentence = (value) => {
    if (value === 1)
      return "The content should follow a narrative structure, telling a cohesive story.";
    if (value === 2)
      return "It should be descriptive, painting a vivid picture with detailed prose.";
    if (value === 3)
      return "Organize the content as a structured list for clarity.";
    if (value === 4)
      return "Use bullet points to organize the content clearly.";
    if (value === 5)
      return "Develop a detailed outline that systematically presents the ideas.";
  };

  const showNotification = (message, isError = false) => {
    const notif = document.createElement("div");
    notif.className = "notification";
    notif.textContent = message;
    notif.style.position = "fixed";
    notif.style.top = "20px";
    notif.style.left = "50%";
    notif.style.transform = "translateX(-50%)";
    notif.style.zIndex = "10000";
    if (isError) {
      notif.style.backgroundColor = "#dc3545";
    }
    document.body.appendChild(notif);
    setTimeout(() => {
      notif.classList.add("fade-out");
      setTimeout(() => {
        document.body.removeChild(notif);
      }, 500);
    }, 1000);
  };

  useEffect(() => {
    if (window.location.pathname !== "/prompter") {
      window.history.pushState({}, "", "/prompter");
    }
  }, []);

  return (
    <div>
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: 0 }}>Prompt Generator</h2>
        <a
          href="/"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "auto",
          }}
        >
          Back to Home
        </a>
      </div>
      <div className="prompter-container">
        <p className="description">
          Customize your prompt by selecting options for each property and
          entering your query.
        </p>
        <div className="query-input">
          <label htmlFor="query">Enter your query:</label>
          <textarea
            id="query"
            rows="3"
            placeholder="What topic would you like content about?"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            style={
              queryInvalid
                ? { width: "100%", border: "1px solid red" }
                : { width: "100%" }
            }
          ></textarea>
        </div>
        <div className="properties-container">
          <div className="slider-group">
            <label htmlFor="humor-slider">
              Humor Level:{" "}
              <span className="slider-value">
                {HUMOR_MAP[Math.round(humor)]}
              </span>
            </label>
            <input
              type="range"
              id="humor-slider"
              min="1"
              max="5"
              step="0.1"
              value={humor}
              onChange={(e) => setHumor(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(humor)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="english-slider">
              English Level:{" "}
              <span className="slider-value">
                {ENGLISH_LEVEL_MAP[Math.round(englishLevel)]}
              </span>
            </label>
            <input
              type="range"
              id="english-slider"
              min="1"
              max="5"
              step="0.1"
              value={englishLevel}
              onChange={(e) => setEnglishLevel(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(englishLevel)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="tech-slider">
              Technical Depth:{" "}
              <span className="slider-value">
                {TECHNICAL_DEPTH_MAP[Math.round(technicalDepth)]}
              </span>
            </label>
            <input
              type="range"
              id="tech-slider"
              min="1"
              max="5"
              step="0.1"
              value={technicalDepth}
              onChange={(e) => setTechnicalDepth(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(technicalDepth)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="length-slider">
              Length:{" "}
              <span className="slider-value">
                {LENGTH_MAP[Math.round(length)]}
              </span>
            </label>
            <input
              type="range"
              id="length-slider"
              min="1"
              max="5"
              step="0.1"
              value={length}
              onChange={(e) => setLength(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(length)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="tone-slider">
              Tone:{" "}
              <span className="slider-value">{TONE_MAP[Math.round(tone)]}</span>
            </label>
            <input
              type="range"
              id="tone-slider"
              min="1"
              max="5"
              step="0.1"
              value={tone}
              onChange={(e) => setTone(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(tone)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="audience-slider">
              Target Audience:{" "}
              <span className="slider-value">
                {AUDIENCE_MAP[Math.round(audience)]}
              </span>
            </label>
            <input
              type="range"
              id="audience-slider"
              min="1"
              max="5"
              step="0.1"
              value={audience}
              onChange={(e) => setAudience(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(audience)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="formality-slider">
              Formality:{" "}
              <span className="slider-value">
                {FORMALITY_MAP[Math.round(formality)]}
              </span>
            </label>
            <input
              type="range"
              id="formality-slider"
              min="1"
              max="5"
              step="0.1"
              value={formality}
              onChange={(e) => setFormality(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(formality)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="creativity-slider">
              Creativity:{" "}
              <span className="slider-value">
                {CREATIVITY_MAP[Math.round(creativity)]}
              </span>
            </label>
            <input
              type="range"
              id="creativity-slider"
              min="1"
              max="5"
              step="0.1"
              value={creativity}
              onChange={(e) => setCreativity(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(creativity)}
            />
          </div>
          <div className="slider-group">
            <label htmlFor="structure-slider">
              Structure:{" "}
              <span className="slider-value">
                {STRUCTURE_MAP[Math.round(structure)]}
              </span>
            </label>
            <input
              type="range"
              id="structure-slider"
              min="1"
              max="5"
              step="0.1"
              value={structure}
              onChange={(e) => setStructure(parseFloat(e.target.value))}
              className="slider"
              style={getSliderStyle(structure)}
            />
          </div>
        </div>
        <button className="generate-button" onClick={generatePrompt}>
          Generate Prompt
        </button>
        {generatedPrompt && (
          <div className="generated-prompt">
            <h2>Generated Prompt:</h2>
            <div className="prompt-box">{generatedPrompt}</div>
          </div>
        )}
      </div>
      <div className="footer">
        <p></p>
      </div>
    </div>
  );
};

export default Prompter;
